<template>
  <Layout>
    <div class="section-title">
      <h6 class="text-left">Dúvida(s) encontrada(s)</h6>
    </div>
    <Card type="card-list" :cards="cards"></Card>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Layout from "@/components/layout/question/Layout.vue";
import Card from "@/components/elements/Card.vue";

export default Vue.extend({
  name: "Questions",
  data() {
    return {
      cards: [],
    };
  },
  components: {
    Layout,
    Card,
  },
  computed: {
    ...mapGetters("eleicaonet", {
      getParamHotsite: "getParamHotsite",
    }),
  },
  mounted() {
    this.cards = this.getParamHotsite.cards;
  },
});
</script>
